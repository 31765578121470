import Vue from 'vue';
import VueRouter from 'vue-router';
import _ from 'lodash';
import { isNilString } from '@/utils';
import config from '@/config';
import { transformToken } from '@/api';
// import { translateRoleCategory } from '@/utils/enums';
import MemberManagement from './member-list';
import statistics from './statistics';
import FaceDiagnose from './faceDiagnose';
import Service from './serviceManager';
import Workbench from './workbench';
import FollowVisit from './followVisit';
//import MyWorkingSchedule from './myWorkingSchedule';
import WorkforceManagement from './workforceManagement';
import InquiryServe from './inquiryServe';
import FollowSettings from './followSettings';
import Pharmacist from './pharmacist';
import AdverseEvent from './adverseEvent';
import CallPlatform from './callPlatform';
import Hanzhong from './hanzhong';
const DefaultLayout = () => import(/* webpackChunkName: "default" */ '@/components/layout/default');

let routers = [
  ...Workbench,
  ...Service,
  ...Pharmacist,
  ...MemberManagement,
  ...FaceDiagnose,
  // ...MyWorkingSchedule,
  ...WorkforceManagement,
  ...statistics,
  ...FollowVisit,
  ...InquiryServe,
  ...FollowSettings,
  ...AdverseEvent,
  ...CallPlatform,
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: config.baseRouter,
  routes: [
    {
      path: '/',
      component: DefaultLayout,
      redirect: '/member-list/index',
      children: routers,
    },
    ...Hanzhong, // 药品信息官网
    {
      path: '/forbid',
      component: () => import(/* webpackChunkName: "forbid" */ '@/pages/forbid'),
      meta: {
        title: '权限不足',
        display: 'none',
      },
    },
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '@/pages/login'),
      meta: {
        title: '登录',
        display: 'none',
      },
    },
    {
      path: '*',
      component: DefaultLayout,
      children: [
        {
          path: '*',
          component: () => import(/* webpackChunkName: "notFound" */ '@/pages/not-found'),
          meta: {
            title: '404',
            noTag: true,
            // auth: false,
          },
        },
      ],
    },
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach(async (to, from, next) => {
  const { token } = to.query;
  const isTokenNil = isNilString(token);

  document.title = _.get(to, 'meta.title', '');
  document.body.scrollTop = 0;

  if (!isTokenNil) {
    // 记录员工平台的token， 用于人脸识别区分
    localStorage.setItem('STAFF_TOKEN', token);
    // 转换token
    const newToken = await transformToken({
      headers: {
        token,
      },
    });

    if (newToken) {
      localStorage.setItem(config.TOKEN_KEY, newToken);
    }
  }

  next();
});

export default router;
