export default [{
    path: 'pharmacist',
    component: () => import(
        /* webpackChunkName: "default" */
        '@/pages/pharmacist/index'
    ),
    meta: {
        title: '药师审方',
        code: 'PZS_SHLB',
        menuIconName: 'check@2x.png',
        roleCategory: 3, // 药师。 identity的枚举太散乱，改为角色判断
    },
}];
