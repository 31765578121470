const defaults = () => import(/* webpackChunkName: "default" */ '@/components/layout/view');

export default [
  {
    path: 'statistics',
    component: defaults,
    meta: {
      title: '数据统计',
      code: 'PZS_SJTJ',
      auth: false,
      menuIconName: 'statistics@3x.png',
      // display: 'none',
    },
    redirect: 'statistics/medicine',
    children: [
      {
        path: 'medicine',
        component: () => import(/* webpackChunkName: "statistics" */ '@/pages/statistics/medicine'),
        meta: {
          title: '运营报表',
          code: 'PZS_SJTJ_YYBB',
          display: 'none',
          auth: false,
          requiresAuth: false,
        },
      },
      {
        path: 'operation',
        component: () => import(/* webpackChunkName: "statistics" */ '@/pages/statistics/operation'),
        meta: {
          title: '医学报表',
          code: 'PZS_SJTJ_YXBB',
          auth: false,
          requiresAuth: false,
        },
      },
      {
        path: 'export-list',
        component: () => import(/* webpackChunkName: "member-detail" */ '@/pages/statistics/export-list'),
        meta: {
          title: '导出列表',
          code: 'PZS_SJTJ_DCLB',
          // auth: false,
          // requiresAuth: false,
        },
      },
      {
        path: 'assessment-list',
        component: () => import(/* webpackChunkName: "member-detail" */ '@/pages/statistics/assessment-list'),
        meta: {
          title: '心理测评报表',
        },
      },
    ],
  },
];
