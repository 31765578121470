function adverseEventFilters(vue) {
  vue.filter('adverseEventFilters', (v, key) => {
    // console.log('\n', 'adverseEventFilters=> ', v, key);

    switch (key) {
      case 'reportCategory': // 报告种类
        console.log('报告种类: ', v);
        return (
          {
            FIRSE: '首次报告',
            TRACK: '跟踪报告',
          }[v] || ''
        );

      case 'reportType': // 报告类型
        console.log('报告类型: ', v);
        return (
          {
            NEW: '新的',
            SERIOUS: '严重',
            COMMON: '一般',
          }[v] || ''
        );

      case 'reportDepartType': // 报告单位类别
        console.log('报告单位类别: ', v);
        return (
          {
            MEDICAL_INSTITUTION: '医疗机构',
            BUSINESS_ENTERPRISE: '经营企业',
            PRODUCTIVE_ENTERPRISE: '生产企业',
            PERSONAL: '个人',
            OTHER: '其他',
          }[v] || ''
        );

      case 'ptGeCode': // 患者性别
        console.log('患者性别: ', v);
        return (
          {
            0: '未知性别',
            1: '男',
            2: '女',
            9: '未说明性别',
          }[v] || ''
        );

      case 'reporterOccupationType': // 报告人职业类型
        console.log('报告人职业类型: ', v);
        return (
          {
            DOCTOR: '医生',
            PHARMACIST: '药师',
            NURSE: '护士',
            OTHER: '其他',
          }[v] || ''
        );

      case 'pastAdverseReaction': // 既往药品不良反应/事件
        console.log('既往药品不良反应/事件: ', v);
        return (
          {
            EXIST: '有',
            NONE: '无',
            UNKNOWN: '不详',
          }[v] || ''
        );

      case 'famAdverseReaction': // 家族药品不良反应/事件
        console.log('家族药品不良反应/事件: ', v);
        return (
          {
            EXIST: '有',
            NONE: '无',
            UNKNOWN: '不详',
          }[v] || ''
        );

      case 'relatedImportantInformation': // 相关重要信息
        console.log('相关重要信息: ', v);
        return (
          {
            SMOKE: '吸烟史',
            DRINK: '饮酒史',
            PREGNANCY: '妊娠期',
            HEPATOPATHY: '肝病史',
            NEPHROPATHY: '肾病史',
            ALLERGY: '过敏史',
            OTHER: '其他',
          }[v] || ''
        );

      case 'drugType': // 药品类型
        console.log('药品类型: ', v);
        return (
          {
            SUSPECT: '怀疑药品',
            COMBINATION: '并用药品',
          }[v] || ''
        );

      case 'medicationFrequencyType': // 用药频率
        console.log('用药频率: ', v);
        return (
          {
            FIX: '固定频率',
            CHANGE: '变动频率',
          }[v] || ''
        );

      case 'eventLevelCode': // 事件等级
        console.log('事件等级: ', v);
        return (
          {
            1: 'I级',
            2: 'II级',
            3: 'III级',
            4: 'IV级',
          }[v] || ''
        );

      case 'eventTypeCode': // 不良事件分类
        console.log('不良事件分类: ', v);
        return (
          {
            1: '医疗类',
            2: '药品类',
            3: '其他类',
          }[v] || ''
        );

      case 'eventResult': // 不良反应/事件结果
        console.log('不良反应/事件结果: ', v);
        return (
          {
            RECOVERY: '痊愈',
            BETTER: '好转',
            NO_BETTER: '未好转',
            UNKNOWN: '不详',
            SEQUELAE: '有后遗症',
            DEATH: '死亡',
          }[v] || ''
        );

      case 'patientStausAfterWithdrawalOrReduce': // 停药或者减量后患者状态
        console.log('停药或者减量后患者状态: ', v);
        return (
          {
            BETTER: '好转',
            NO_BETTER: '未好转',
            NO_OBVIOUS: '不明',
            NO_WITHDRAWAL_OR_REDUCTION: '未停药或未减量',
          }[v] || ''
        );

      case 'sameStatusAfetrSuspiciousDrugsAgain': // 再次使用可疑药品后是否出现相同反应/事件
        console.log('再次使用可疑药品后是否出现相同反应/事件: ', v);
        return (
          {
            YES: '是',
            NO: '否',
            UNKNOWN: '不明',
            NO_USED_AGAIN: '未再使用',
          }[v] || ''
        );

      case 'effectOnOldCondition': // 对原患疾病的影响
        console.log('对原患疾病的影响: ', v);
        return (
          {
            NO_OBVIOUS: '不明显',
            LONGER_OF_DISEASE: '病程延长',
            AGGRAVATION: '病情加重',
            CAUSE_SEQUELAE: '导致后遗症',
            CAUSE_DEATH: '导致死亡',
          }[v] || ''
        );

      default:
        return '';
    }
  });
}

export default adverseEventFilters;
