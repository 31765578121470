export default [
  {
    path: '/hanzhong',
    component: () => import('@/pages/hanzhong/index'),
    meta: {
      display: 'none',
    },
    children: [
      {
        name: 'hanzhongHomepage',
        path: 'homepage',
        component: () => import('@/pages/hanzhong/homepage'),
        meta: {
          title: '首页',
          display: 'none',
        },
      },
      {
        name: 'hanzhongCompanyInfo',
        path: 'companyInfo',
        component: () => import('@/pages/hanzhong/companyInfo'),
        meta: {
          title: '公司介绍',
          display: 'none',
        },
      },
      {
        name: 'hanzhongDrugInfo',
        path: 'drugInfo',
        component: () => import('@/pages/hanzhong/drugInfo'),
        meta: {
          title: '药品信息',
          display: 'none',
        },
      },
      {
        name: 'hanzhongDrugInfoDetail',
        path: 'drugInfo/:id',
        component: () => import('@/pages/hanzhong/drugInfoDetail'),
        meta: {
          title: '药品详情',
          display: 'none',
          parent: 'hanzhongDrugInfo',
        },
      },
      {
        name: 'hanzhongPeriodicals',
        path: 'periodicals',
        component: () => import('@/pages/hanzhong/periodicals'),
        meta: {
          title: '科普信息',
          display: 'none',
        },
      },
      {
        name: 'hanzhongPeriodicalsDetail',
        path: 'periodicals/:id',
        component: () => import('@/pages/hanzhong/periodicalsDetail'),
        meta: {
          title: '科普信息',
          display: 'none',
          parent: 'hanzhongPeriodicals',
        },
      },
      {
        name: 'hanzhongContactUs',
        path: 'contactUs',
        component: () => import('@/pages/hanzhong/contactUs'),
        meta: {
          title: '联系我们',
          display: 'none',
        },
      },
    ],
  },
];
