export default [
    {
        path: 'services',
        component: () => import(/* webpackChunkName: "default" */ '@/pages/serviceManager/index'),
        // TODO  权限码未更替
        meta: {
            title: '诊室服务',
            code: 'PZS_FWLB',
            menuIconName: 'consulting@3x.png',
        },
    },
    {
        path: 'services/create-detail',
        component: () => import(/* webpackChunkName: "member-detail" */ '@/pages/serviceManager/createService'),
        meta: {
            title: '创建问诊服务',
            code: 'PZS_FWLB_EDIT',
            display: 'none',
        },
    },
    {
        path: 'services/lookDetail',
        component: () => import(/* webpackChunkName: "member-detail" */ '@/pages/serviceManager/lookDetail'),
        meta: {
            title: '服务记录',
            code: 'PZS_FWLB_CKXQ',
            display: 'none',
        },
    },
    {
        path: 'services/audit',
        component: () => import(/* webpackChunkName: "member-detail" */ '@/pages/serviceManager/auditing'),
        // TODO, 未更改权限码
        meta: {
            title: '待审核',
            code: 'PZS_FWLB_CKXQ',
            display: 'none',
        },
    },
];
