import request from '@/utils/fetch';

// 获取监管功能开关
export const querySupervisionSwitch = (params) =>
  request({
    url: '/online-hospital-doctor/api/pc/v1/prescription/supervise/switch',
    method: 'post',
    params,
  });

// 意愿认证-验证码
export const getMessageCode = (params) =>
  request({
    url: '/online-hospital-doctor/api/pc/v1/prescription/sms/send',
    method: 'post',
    params,
  });

// 处方短信验证
export const verifyMessageCode = (params) =>
  request({
    url: '/online-hospital-doctor/api/pc/v1/prescription/sms/send/verification',
    method: 'post',
    params,
  });

// 电子病历新增
export const medicalRecordInfoAdd = (params) =>
  request({
    // url: '/online-hospital-service/api/v1/medicalRecordInfo/add',
    url: '/online-hospital-doctor/api/wx/v1/patientRecord/insert',
    method: 'post',
    params,
  });

// 电子病历查询接口
export const medicalRecordInfoQuery = (params) =>
  request({
    url: `/online-hospital-service/api/v1/medicalRecordInfo/query`,
    method: 'get',
    params,
  });

export const medicalRecordSign = (params) =>
  request({
    url: `/online-hospital-supervise/api/supervise/doctorSign?doctorAccountId=${params.doctorAccountId}&elecReportId=${params.elecReportId}`,
    method: 'post',
    params: {},
  });

//
export const getPresciptionDetail = (id) =>
  request({
    url: '/online-hospital-doctor/api/wx/v1/prescription/detail',
    method: 'post',
    params: {
      prescriptionId: id,
      source: 6000160001,
    },
  });

// 修改预约时间接口
export const updateAppointmentTime = (params) => {
  return request({
    url: '/online-hospital-doctor/api/wx/v1/appointmentRecord/insert',
    method: 'post',
    loading: false,
    params,
  });
};

// 查询e签宝人脸识别结果 (通用接口，传递派诊所token)
export const getFaceAuthResult = (params = {}) =>
  request({
    url: '/online-hospital-doctor/api/wx/v1/patientRecord/getFaceAuthResult',
    method: 'post',
    params,
  });

// 查询e签宝人脸识别结果 (登录专用，此时还没有派诊所token，传递员工平台token)
export const getLoginFaceAuthResult = (params = {}, headers = {}) =>
  request({
    url: '/online-hospital-doctor/api/wx/v1/patientRecord/getFaceAuthResult',
    method: 'post',
    params,
    headers,
  });

//  登录人脸识别获取链接 (登录专用，此时还没有派诊所token，传递员工平台token)
export const getLoginFaceAuditUrl = (params, headers) =>
  request({
    url: '/online-hospital-doctor/api/wx/v1/doctor/getFaceAuditUrl',
    params,
    method: 'post',
    headers,
  });

// 获取E签宝签名链接(开处方)
export const getFaceAuthUrl = (params = {}) =>
  request({
    url: '/online-hospital-doctor/api/wx/v1/patientRecord/getFaceAuthUrl',
    method: 'post',
    params,
  });
// 获取E签宝签名链接(诊室服务列表---医生角色去审核)
export const getFaceAuthUrlOfDoctor = (params = {}) =>
  request({
    url: '/online-hospital-doctor/api/wx/v1/doctor/face-audit',
    method: 'post',
    params,
  });

// 电子病历签名
export const getElecMedicRecordSign = (params = {}) =>
  request({
    url: '/online-hospital-doctor/api/wx/v1/patientRecord/getElecMedicRecordSign',
    method: 'post',
    params,
  });
// 修改预约时间接口
export const getUserSign = (params) => {
  return request({
    url: '/online-hospital-doctor/api/video/v1/vc/usersign',
    method: 'post',
    loading: false,
    params,
  });
};

// 发送短信-发起视频问诊时
export const sendMsgByVideoRequire = (params) => {
  return request({
    url: '/online-hospital-service/core/api/v1/order-send-im/sendMsgByVideoRequire',
    method: 'post',
    loading: false,
    params,
  });
};

// 发送短信-未接通，系统挂断
export const sendMsgByNoConnect = (params) => {
  return request({
    url: '/online-hospital-service/core/api/v1/order-send-im/sendMsgByNoConnect',
    method: 'post',
    loading: false,
    params,
  });
};

// 医助第一次回复埋点
export const saveServiceLogInfo = (params) => {
  return request({
    url: '/online-hospital-service/api/v1/service-order/saveServiceLogInfo',
    method: 'post',
    loading: false,
    params,
  });
};

// 去审核同步监管
export const upLoginSupervise = (params) => {
  return request({
    url: '/online-hospital-doctor/api/wx/v1/doctor/upLoginSupervise',
    method: 'post',
    loading: false,
    params,
  });
};

// 对接荣联外呼拨号
export const doctorDialOutApi = (params) => {
  return request({
    url: '/online-hospital-service/api/v1/doctorPhoneConsult/dialout',
    method: 'post',
    loading: false,
    params,
  });
};
