export default [
  {
    path: 'callPlatform',
    component: () => import('@/pages/callPlatform/index'),
    meta: {
      title: '呼叫平台账号绑定',
      code: 'ALL',
      menuIconName: 'user.png',
      roleCategory: 4, // 运营
    },
  },
];
