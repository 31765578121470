import _ from 'lodash';
import { isNull } from '@/utils';
import { getUserPermission } from '../api';
import { SET_PERMISSIONINFO } from './mutation-type';

// 获取用户信息
const getPermissionCode = ({ commit }) => new Promise((resolve, reject) => {
    getUserPermission({ subsys: 27 })
        .then((res) => {
            const permissionData = _.chain(res.data)
                .get(['data'])
                .map((item) => _.get(item, ['sourceCode']))
                .filter((item) => !isNull(item))
                .value();
            commit(SET_PERMISSIONINFO, permissionData);
            resolve(permissionData);
        })
        .catch((e) => reject(e));
});

export default {
    getPermissionCode,
};
