/*
 * @Author: mengying.yao
 * @Email: mengying.yao@medbanks.cn
 * @Date: 2019-11-14 11:35:15
 * @Last Modified by: huihua.he
 * @Last Modified time: 2021-07-23 16:13:39
 * @Description: 项目配置文件
 * @Route: Route
 */
import base from './base';
import dev from './config.dev';
import prod from './config.prod';
import test from './config.test';

let config = {};

if (process.env.NODE_ENV === 'production' && !process.env.VUE_APP_ISUAT) {
    config = { ...prod, isProd: true, serve:'prod' };
} else if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_ISUAT) {
    config = { ...test, isProd: true, serve:'test'};
} else {
    config = { ...dev, isProd: false, serve:'dev'};
}

export default { ...base, ...config };
