const defaults = () => import(/* webpackChunkName: "default" */ '@/components/layout/view');

const identity = [1, 58, 59, 56, 57, 60];

export default [{
    path: 'followSettings',
    component: defaults,
    meta: {
        title: '随访设置',
        code: 'PZS_GZT',
        auth: false,
        menuIconName: 'followSettings@3x.png',
        // display: 'none',
        // display: (!identity.includes(Number(localStorage.getItem('identity'))) || Number(localStorage.getItem('identity')) === 57 || Number(localStorage.getItem('identity')) === 56) ? 'block' : 'none',


    },
    redirect: 'forms',
    children: [{
        path: 'forms',
        component: () => import('@/pages/followSettings/index'),
        meta: {
            title: '随访表单库',
            code: 'SFSZ_SFBDK',
            // display: (Number(localStorage.getItem('identity')) === 57 || Number(localStorage.getItem('identity')) === 56) ? 'block' : 'none',
        },
    },
    {
        path: 'forms/add',
        component: () => import('@/pages/followSettings/index'),
        meta: {
            title: '随访表单详情',
            code: 'PZS_WDPB',
            display: 'none',
        },
    },
    {
        path: 'forms/detail',
        component: () => import('@/pages/followSettings/index'),
        meta: {
            title: '随访表单详情',
            code: 'PZS_WDPB',
            display: 'none',
        },
    },
    {
        path: 'plans',
        component: () => import('@/pages/followSettings/index'),
        meta: {
            title: '随访计划库',
            code: 'SFSZ_SFJHK',
            // display: !identity.includes(Number(localStorage.getItem('identity'))) ? 'block' : 'none',
        },
    },
    {
        path: 'plans/edit',
        component: () => import('@/pages/followSettings/index'),
        meta: {
            title: '详情页',
            code: 'PZS_PBHZ',
            display: 'none',
        },
    },
    {
        path: 'plans/detail',
        component: () => import('@/pages/followSettings/index'),
        meta: {
            title: '详情页',
            code: 'PZS_PBHZ',
            display: 'none',
        },
    },
    {
        path: 'tasks/create',
        component: () => import('@/pages/followSettings/index'),
        meta: {
            title: '创建随访任务',
            code: 'PZS_PBHZ',
            display: 'none',
        },
    }
    ],
}];
