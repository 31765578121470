export default [
    {
        path: 'workbench',
        component: () => import(/* webpackChunkName: "default" */ '@/pages/workbench/index'),
        meta: {
            title: '工作台',
            code: 'PZS_GZT',
            menuIconName: 'workbench@3x.png',
        },
    },
];
