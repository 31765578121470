import Vue from 'vue';
import ElementUI from 'element-ui';
import VueClipboard from 'vue-clipboard2';
import Brick from '@med/brick';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import router from '@/router';
import store from '@/store';
import configs from '@/config';
import fetch from '@/utils/fetch';
import $observer from '@/utils/communicate';
import filters from '@/filters';
import App from './App';

import '@/utils/directives';
import '@/assets/styles/normalize';
import '@/assets/styles/common';
import '@/assets/styles/rw';
import '@med/brick/lib/style/theme/default/index';

// 引入iconfont
import '@/assets/iconfont/doctorDayrid/iconfont.css';

import tinymce from 'tinymce';

Vue.use(Brick);
Vue.use(VueClipboard);
Vue.use(filters);

Vue.prototype.$message = ElementUI.Message;
Vue.prototype.$confirm = ElementUI.MessageBox;
Vue.prototype.$bkConfig = configs;
Vue.prototype.$fetch = fetch;
Vue.prototype.$observer = $observer;
Vue.prototype.$tinymce = tinymce;
Vue.use(ElementUI);

// window.Vue = Vue;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://483ca2ce10204846aa21072bc0c2483d@sentry.medbanks.cn/13',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
