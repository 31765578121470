export default [
    {
        path: 'inquiryServer',
        component: () => import( /* webpackChunkName: "default" */ '@/pages/inquiryServe/index'),
        meta: {
            title: '问诊服务接单',
            code: 'PZS_MZGL',
            hideSide: true, //隐藏侧边栏
            noCache: true,
            display: 'none',
        },

    },
];