/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-12-12 15:06:52
 * @Last Modified by: huihua.he
 * @Last Modified time: 2021-06-07 11:42:02
 * @Description: mutation常量管理
 * @Route: Route
 */
export const DEMO = 'DEMO';
// 设置权限信息常量
export const SET_PERMISSIONINFO = 'SET_PERMISSIONINFO';
