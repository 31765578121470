export default [
  {
    path: 'faceDiagnonse',
    component: () => import(/* webpackChunkName: "default" */ '@/pages/faceDiagnose/index'),
    // TODO   权限码未改
    meta: {
      title: '健康咨询管理',
      code: 'PZS_MZGL',
      menuIconName: 'heath-consult@3x.png',
    },
  },
  {
    path: 'faceDiagnonse/detail',
    component: () => import(/* webpackChunkName: "member-detail" */ '@/pages/faceDiagnose/detail'),
    meta: {
      title: '健康咨询活动详情页',
      code: 'PZS_MZGL_MZXQ',
      display: 'none',
    },
  },
];
