import { querySupervisionSwitch } from '@/api/serviceExtend';

export default {
  namespaced: true,
  state: {
    supervisionSwitch: {
      switchMainFlag: false, // 主开关
      switchDoctorSignFlag: false, // 医生签名，意愿认证
      switchElectronicRecordFlag: false, // 电子病历人脸识别、终止就诊规范提示 （电子病历模块改为初复诊控制）
      switchPatientRelatedFlag: false, // 紧急联系人、现住址
      switchPharmacistPrescriptionFlag: false, // 药师审方
      switchVideoAuthenticationFlag: false, // 视频认证
      operationRoleFlag: false, // 排班汇总菜单
    },
  },
  getters: {
    supervisionSwitch: (state) => {
      return state.supervisionSwitch;
    },
  },
  mutations: {
    setSupervisionSwitch(state, data) {
      let supervisionSwitch = {
        ...state.supervisionSwitch,
        ...data,
      };

      state.supervisionSwitch = supervisionSwitch;
    },
  },
  actions: {
    getSupervisionSwitch: ({ commit }, params) => {
      return querySupervisionSwitch(params).then((res) => {
        commit('setSupervisionSwitch', res.data || {});
      });
    },
  },
};
