/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-11-08 11:05:16
 * @Last Modified by: Huihuahe
 * @Last Modified time: 2021-01-13 16:10:21
 * @Last Modified by: zhifen.zhu
 * @Last Modified time: 2022-02-18 11:41:47
 * @Description: 统一接口定义
 * @Route: Route
 */

import _ from 'lodash';
import request from '@/utils/fetch';
import { catchWith } from '@/utils';

export async function getSpk(params) {
  return request({
    url: '/api/inneruserproject/v001/login/spk',
    method: 'post',
    params,
  });
}

// 中台登录
export async function userLoginApi(params) {
  return request({
    url: '/online-hospital-admin/open/v1/user/user_login',
    method: 'post',
    params,
  });
}

// 用户退出登陆
export const userLoginout = (params) =>
  request({
    url: '/master-data/user/loginout',
    params,
  });

// 获取用户权限TODO
// 原链接：'/master-data/user/permission'
export const getUserPermission = (params) =>
  request({
    url: '/api/inneruserproject/v001/auth/page/query_auths',
    params,
    independent: true,
  });
// export const getUserPermission = (params) => request({
//     url: '/master-data/inneruserproject/v001/auth/page/query_auths',
//     params,
//     independent: true,
// });

// 获取用户信息TODO
// 原链接：/master-data/user/information
export const userInfoApi = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/inner-user/user/getAccountInfo',
    params,
    independent: true,
  });
// export const userInfoApi = (params) => request({
//     url: '/master-data/inneruserproject/v001/user/get_account_info',
//     params,
//     independent: true,
// });
// 刷新token有效期，无操作1小时失效
export const renewalToken = () =>
  request({
    url: '/api/inneruserproject/v001/login/renewal_token',
    method: 'get',
    independent: true,
  });

// 录入健康信息
export const upLoad = (params) =>
  request({
    // url: '/api/hospital-doctor-admin/v1/archieve/update',
    url: '/api/hospital-doctor-admin/v1/archieve/save',
    method: 'post',
    params,
  });

// 获取医生团队
export const getTeamsQuer = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/archieve/healthTeamsQuery',
    params,
  });

// 新增健康评估
export const setEvaluate = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/health/evaluate',
    method: 'post',
    params,
  });

// 获取健康评估
export const getEvaluate = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/health/evaluate/find-by-evaluateId/${params}`,
    method: 'get',
  });

// 更新健康评估
export const updateEvaluate = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/health/evaluate/${params.id}`,
    params,
    method: 'post',
  });

// 更新健康评估发布状态
export const updateEvaluateStatus = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/health/evaluate/update-publish-status/${params.id}`,
    params,
    method: 'post',
  });

// 获取用户信息
export const userInfo = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/member/details',
    params,
    method: 'get',
  });
// 更新用户信息
export const updateUserInfo = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/memberlist/details/update',
    params,
    method: 'post',
  });

// 获取用户信息
export const userBaseInfo = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/member/detail',
    params,
    method: 'get',
  });
// 获取未脱敏的用户信息
export const userBaseInfo2 = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/member/detail2',
    params,
    method: 'get',
  });
// 获取专属医生信息
export const getDoctorInfo = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/healthTeam/insuranceDoctorQuery',
    params,
    method: 'get',
  });

// 发布
export const archivesIssue = (params) =>
  request({
    // url: '/api/hospital-doctor-admin/v1/archieve/archivesIssue',
    url: '/api/hospital-doctor-admin/v1/archieve/publish',
    params,
    method: 'post',
  });

// 取消发布
export const cancelRelease = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/archieve/cancelRelease',
    params,
    method: 'post',
  });

// 获取地址
export const getAddress = (params) =>
  request({
    url: '/master-data/api/region/list',
    params,
  });

// 查询用户医生
export const DoctorQuery = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/healthTeam/insuranceDoctorQuery',
    params,
  });

// 企业面诊-获取面诊日历
export const appointmentCalendar = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/appointment-calendar',
    params,
    method: 'post',
  });

// 企业面诊-获取面诊详情
export const companyDetail = (id) =>
  request({
    url: `/api/hospital-doctor-admin/v1/corporation-diagnosis/detail/${id}`,
    id,
    method: 'get',
  });

// 企业面诊-删除面诊
export const removeDiagnosis = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/remove-diagnosis',
    params,
    method: 'post',
  });

// 企业面诊-删除已选医生
export const removeDoctor = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/remove-doctor',
    params,
    method: 'post',
  });

// 企业面诊-保存预约日历
export const saveCalendar = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/submit-calendar',
    params,
    method: 'post',
  });

//  ---------------- 诊室服务 头部信息 start-------------------------

// 服务管理-会员个人信息接口
export const personBaseInfo = (id) =>
  request({
    url: `/api/hospital-doctor-admin/v1/member/base-info/${id}`,
    id,
    method: 'get',
  });

// 服务管理-详情-服务信息
export const getServiceInfo = (serviceId) =>
  request({
    url: `/api/hospital-doctor-admin/v1/service/detail2/${serviceId}`,
    serviceId,
    method: 'get',
  });

// 随访任务详情(v2.3)
export const visitTask = (visitTaskId) =>
  request({
    url: `/online-hospital-doctor/v1/visit/task/${visitTaskId}`,
    visitTaskId,
    method: 'get',
  });

// 服务单接口-根据服务单获取用户信息(v2.4)
export const customerInfo = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/service/customer-info',
    method: 'get',
    params,
  });

//  ---------------- 诊室服务 头部信息 end-------------------------

// 服务管理-创建服务
export const serviceAdd = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/service/add',
    params,
    method: 'post',
    independent: true,
  });

// 服务管理-查询医生团队接口
export const doctorTeam = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/service/getDoctorTeam',
    params,
    method: 'get',
  });

// 服务管理-创建服务-备孕咨询-药品查询
export const drugCommonist = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/master-data/drug-common-list',
    params,
    method: 'get',
  });

export const departmentList = (params) =>
  request({
    url: '/online-hospital-doctor/master-data/api/department/list',
    params,
    method: 'get',
  });

// 服务管理-创建服务-初诊医院
export const hospitalList = (params) =>
  request({
    url: '/online-hospital-doctor/master-data/api/hospital/list',
    params,
    method: 'get',
  });

// 服务管理-查看详情-问诊记录 V2.3
export const inquiryLog = (patientId) =>
  request({
    url: `/online-hospital-doctor/v1/member/serviceRecord/${patientId}`,
    patientId,
    method: 'get',
  });

// 服务管理-病历信息接口
export const medicalHistoryRecord = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/service/medicalHistoryRecord/${params.serviceOrderId}`,
    params,
    method: 'get',
  });

// 服务管理-信息收集接口
export const patientInfoCollect = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/service/patientInfoCollectList/${params.serviceOrderId}`,
    params,
    method: 'get',
  });

// 服务管理-评估报告-列表  businessId
export const reportList = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/health/report/report-list',
    params,
    method: 'get',
  });

// 服务管理-预诊信息接口
export const diagnosticRecord = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/service/diagnosticRecord/${params.serviceOrderId}`,
    params,
    method: 'get',
  });

// 服务管理-查询服务单后续服务列表- 创建报告接口
export const resultFollowList = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/service/result-follow-list',
    params,
    method: 'get',
  });

// 会员列表-发起问诊服务- 激活用户
export const serviceAddUser = (params) =>
  request({
    url: '/online-hospital-admin/v1/user/active',
    params,
    method: 'post',
    independent: true,
  });

// 面诊管理- 预约数据
export const orderDownloadData = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/corporation-diagnosis/export-it/${params.id}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
    method: 'get',
  });

// 会员详情-扩展信息
export const memberExtInfo = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/memberlist/memberExtInfo/${params.personId}`,
    params,
    method: 'get',
  });

// 查询权益列表
export const findGuaranteedContent = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/memberlist/findGuaranteedContent/${params.personId}`,
    params,
    method: 'get',
  });

// 根据医疗服务计划id查询权益
export const findGuaranteedContentByMedicineOrderItemId = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/memberlist/findGuaranteedContentByMedicineOrderItemId/${params.orderItemId}`,
    params,
    method: 'get',
  });

// V1.9  2期
export const close = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/corporation-diagnosis/backend/close/${params.id}`,
    params,
    method: 'post',
  });

// 体检报告-保存,预览，:发布2
// 体检报告-保存,预览，暂存 & 暂存并预览 1
export const physicalReportSave = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/health-examination-report/save-or-update',
    params,
    method: 'post',
  });

// V1.9 2期
export const touchDown = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/corporation-diagnosis/backend/touchdown/${params.id}`,
    params,
    method: 'post',
  });

// V1.9 2期  新增状态下创建
export const diagonsisSave = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/save',
    params,
    method: 'post',
  });

// 体检报告-详情
export const getPhysicalDetail = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/health-examination-report/detail',
    params,
    method: 'get',
  });

// 体检报告-取消
export const cancalPhysicalReport = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/health-examination-report/cancel/${params.id}`,
    params,
    method: 'post',
  });

// V1.9 2 期  服务地点
export const provinceCityOptions = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/backend/province-city-options',
    params,
    method: 'get',
  });

// 健康评估报告- 预览医生信息
export const getDoctorInfoPreView = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/sys-common/doctor-info',
    params,
    method: 'get',
  });

// 会员管理- 获取列表
export const memberlist = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/member/page',
    params,
    method: 'get',
  });

// 操作日志
export const opreaionLogs = (params) =>
  request({
    url: '/online-hospital-admin/v1/sys-common/logs',
    params,
    method: 'get',
  });

// 获取城市列表
export const getCityList = (params) =>
  request({
    url: '/master-data/api/region/search',
    params,
    method: 'get',
  });

// 根据服务单获取体检报告(v2.2)
export const getLatestReport = (serviceId) =>
  request({
    url: `/api/hospital-doctor-admin/v1/health-examination-report/latest-report/${serviceId}`,
    serviceId,
    method: 'get',
  });

// 根据服务单获取最新健康评估(v2.2)
export const getLatestEvalute = (serviceId) =>
  request({
    url: `/api/hospital-doctor-admin/v1/health-evaluate/latest-evaluate/${serviceId}`,
    serviceId,
    method: 'get',
  });

// 企业面诊-分页查询公司(v1.9)
export const companiesList = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/page-companies',
    params,
    method: 'get',
  });

// 紧急加号
export const companiesListDiagnosis = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/page-companies-with-diagnosis',
    params,
    method: 'get',
  });

// 企业面诊-加号/预约下拉成员
export const getMemberName = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/member-options',
    params,
    method: 'get',
  });

// 企业面诊-成员信息
export const getMemberInfo = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/member-info',
    params,
    method: 'get',
  });

// 用户处于未激活状态，调用激活接口
export const activeUser = (params) =>
  request({
    url: '/online-hospital-admin/v1/user/active',
    params,
    method: 'post',
    independent: true,
  });

// 省市接口
export const addressList = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/sys-common/addressList',
    params,
    method: 'get',
  });

// 会员专属医生列表 【创建问诊服务】
export const ownDoctors = (personId) =>
  request({
    url: `/api/hospital-doctor-admin/v1/member/own-doctors/${personId}`,
    personId,
    method: 'get',
  });

// 创建问诊服务 【获取医生列表V2.2】
export const doctorOptions = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/healthTeam/doctor-options',
    params,
    method: 'get',
  });

// 创建问诊服务 【根据医生获取医生日历】
export const calendar = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/frontend/calendar',
    params,
    method: 'post',
    independent: true,
  });

//  入口是正常预约的提交接口  /v1/corporation-diagnosis/frontend/common-apply
export const commonApply = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/frontend/common-apply',
    params,
    method: 'post',
    independent: true,
  });

// 入口是 紧急加号
export const forceApply = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/frontend/force-apply',
    params,
    method: 'post',
    independent: true,
  });

// 审核列表
export const auditList = (params) =>
  request({
    url: '/online-hospital-doctor/api/wx/v1/order/auditList',
    params,
    method: 'get',
  });

// 获取内部用户信息(v2.2)
export const innerUserInfo = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/inner-user/info',
    params,
    method: 'get',
  });

// token转换器(v2.2)
export async function transformToken(params = {}) {
  const { data: response } = await catchWith(
    request({
      url: '/online-hospital-doctor/api/wx/v1/doctor/token-magician	',
      independent: true,
      ...params,
    }),
  );

  return _.get(response, ['data', 'data']);
}

// 健康资料 列表
export const getHealthInformationList = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/archieve/find-health-material',
    params,
    method: 'get',
  });

// 健康资料体检报告---删除
export const deleteMedicalExaminationReport = (params) =>
  request({
    url: `/api/hospital-doctor-admin/v1/archieve/delete-report/?checkFileId=${params}`,
    // params,
    method: 'get',
  });

// 健康资料上传体检报告
export const uploadMedicalExaminationReport = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/archieve/upload-medical-report',
    params,
    method: 'post',
  });

/** *************************************** 运营角色排班相关 👇 ****************************************************** */
// 查找城市
export const provinceCityList = (params) =>
  request({
    url: '/master-data/api/region/province-cities',
    params,
    method: 'get',
  });

// 运营查询医生日程
export const scheduleOperateList = (params) =>
  request({
    url: '/online-hospital-admin/admin/auth/api/v1/schedule/scheduleOperateList',
    params,
    method: 'post',
  });

// 运营角色对日程添加备注
export const scheduleAddRemark = (params) =>
  request({
    url: '/online-hospital-admin/admin/auth/api/v1/schedule/addRemark',
    params,
    method: 'post',
  });

// 获取创建问诊服务基础信息 证件号和手机号脱敏数据
export const getMemberInfoEncrypt = (params) =>
  request({
    url: 'api/hospital-doctor-admin/v1/corporation-diagnosis/member-info-encrypt',
    params,
    method: 'get',
  });

// 获取非脱敏的数据

/**
 * 接口传参要求：
 *
 * 工作台页面走会员服务页的枚举
 *
 * common: 会员名称(memberName)、企业名称(companyName)、查询字段名(fieldName)、字段类型(fieldType)、页面类型(pageType)
 *
 * 思派医药服务页： 服务单id(serviceId)、自然人id(memberId)
 *
 * 诊室服务页：服务单id(serviceId)
 *
 * 会员服务页： 自然人id(memberId)
 *
 */

/**
 * fieldType 枚举
 *
 * 诊室服务页面：
 *
 * 登录手机： 1
 * 接听手机： 2
 * --------------------------
 *
 * 会员列表页面：
 *
 * 邮箱：3
 * 登录手机：4
 * 其他手机：5
 * 联系人电话：6
 * 健康咨询手机号：7
 *
 * -----------------------
 *
 * 思派医药服务页面：
 *
 * 患者主页： 8
 * 主诉： 9
 */

/**
 * pageType 枚举
 *
 * 诊室服务页： 1
 * 会员服务页：2
 * 思派医药服务：3
 */

export const getSecretData = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/desensitized/query/desensitized',
    params,
    method: 'post',
  });

// 查询用户有无待随访计划
export const searchTaskList = (params) =>
  request({
    url: '/online-hospital-doctor/v1/visit/task/list',
    params,
    method: 'post',
  });

// 查询用户计划名称列表
export const getPlanList = (params) =>
  request({
    url: '/online-hospital-doctor/api/wx/v1/plan/getPlanPage',
    params,
    method: 'get',
  });

// 查询审核处方列表
export const getPharmacistList = (params) =>
  request({
    url: '/online-hospital-doctor/api/pc/v1/prescription/page',
    params,
    method: 'get',
  });
// 查询审核处方详情
export const getPharmacistDetail = (params) =>
  request({
    url: '/online-hospital-doctor/api/pc/v1/prescription/detail',
    params,
    method: 'post',
  });
// 处方审核
export const setPharmacistAudit = (params) =>
  request({
    url: '/online-hospital-doctor/api/pc/v1/prescription/audit',
    params,
    method: 'post',
  });
// 处方短信发送
export const sendPharmacistSend = (params) =>
  request({
    url: '/online-hospital-doctor/api/pc/v1/prescription/sms/send',
    params,
    method: 'post',
  });
// 处方短信校验
export const pharmacistVhrification = (params) =>
  request({
    url: '/online-hospital-doctor/api/pc/v1/prescription/sms/send/verification',
    params,
    method: 'post',
  });

// 判断是否展示购药订单入口
export const checkBmoVisible = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/dingdang/company/blackList',
    params,
    method: 'post',
  });

// 获取用户信息
export const getUserInfo = (personId) =>
  request({
    url: `/api/hospital-doctor-admin/v1/member/getPersonInfo/${personId}`,
    method: 'get',
  });
// 健康档案发送消息
export const sendArchMessage = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/archieve/sendNoticeMessage',
    params,
    method: 'post',
  });

// 查询分支机构列表信息
export const branchOrgOptionsApi = (params) =>
  request({
    url: '/api/hospital-doctor-admin/v1/corporation-diagnosis/branch-org/list',
    params,
    method: 'get',
  });

// 心率脉搏历史记录
export const heartPulseRecordsApi = (params) =>
  request({
    url: '/online-hospital-doctor/admin/v1/archieve/heartPulseRecords',
    params,
    method: 'post',
  });

// 的血压过程历史记录
export const bloodRecordsApi = (params) =>
  request({
    url: '/online-hospital-doctor/admin/v1/archieve/bloodRecords',
    params,
    method: 'post',
  });

// bmi过程记录
export const bmiRecordsApi = (params) =>
  request({
    url: '/online-hospital-doctor/admin/v1/archieve/bMIRecords',
    params,
    method: 'post',
  });

// 腰臀比过程记录
export const whrRecordsApi = (params) =>
  request({
    url: '/online-hospital-doctor/admin/v1/archieve/wHRRecords',
    params,
    method: 'post',
  });

// 档案呼吸和体温记录
export const temOrBrtRecordsApi = (params) =>
  request({
    url: '/online-hospital-doctor/admin/v1/archieve/temOrBrtRecords',
    params,
    method: 'post',
  });

// 健康档案历史记录导出
export const archieveRecordExportApi = (params) =>
  request({
    url: '/online-hospital-doctor/admin/v1/archieve/record/export',
    params,
    method: 'post',
    independent: true,
  });