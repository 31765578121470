/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2020-03-13 13:26:10
 * @Last Modified by: zhifen.zhu
 * @Last Modified time: 2022-02-14 15:06:28
 * @Description: 测试环境配置
 * @Route: Route
 */

export default {
  env: 'test',
  baseUrl: '//api-gateway.medbanks-test.com',
  // baseUrl: 'http://192.168.38.185:2788',
  baseRouter: '/checkToken/',
  wsUrl: 'wss://api-gateway.medbanks-test.com/ws/connect',
  formChannels: 'ed1d136ff42ccec5',
  // 微应用配置
  MICRO_APPS: {
    FOLLOW_VISIT: {
      name: 'follow-visit',
      // entry: 'https://him.spcare.cn/checkToken/followVisit',
      // activeRule: '/followVisit',
      entry: 'https://him.medbanks-test.com/',
      container: '#FOLLOW_VISIT',
    },
  },
  outer: 'https://him.medbanks-test.com',
  secret: 'v6dWRMYpET2qjD0i', // 登录密码加密密钥
  signatureResultPage: 'https://ihd.medbanks-test.com/signatureResult', // E签宝人脸识别跳转页
};
