import store from '@/store';

const defaults = () => import(/* webpackChunkName: "default" */ '@/components/layout/view');

const identity = [1, 58, 59, 56, 57, 60];

export default [
  {
    path: 'workforce',
    component: defaults,
    meta: {
      title: '排班管理',
      code: 'PZS_GZT',
      auth: false,
      menuIconName: 'workbench@3x.png',
      // display: 'none',
      display: () =>
        !identity.includes(Number(localStorage.getItem('identity'))) ||
        Number(localStorage.getItem('identity')) === 57 ||
        Number(localStorage.getItem('identity')) === 56
          ? 'block'
          : 'none',
    },
    redirect: 'workforce/myWorkingSchedule',
    children: [
      {
        path: 'myWorkingSchedule',
        component: () => import('@/pages/myWorkingSchedule/index'),
        meta: {
          title: '我的排班',
          code: 'PZS_WDPB',
          display: () =>
            Number(localStorage.getItem('identity')) === 57 || Number(localStorage.getItem('identity')) === 56
              ? 'block'
              : 'none',
        },
      },
      {
        path: 'workforceManagement',
        component: () => import('@/pages/workforceManagement/index'),
        meta: {
          title: '排班汇总',
          code: 'PZS_PBHZ',
          // display: () => {
          //   if (store.state.supervision.supervisionSwitch?.operationRoleFlag) {
          //     return 'block';
          //   }
          //   return !identity.includes(Number(localStorage.getItem('identity'))) ? 'block' : 'none';
          // },
        },
      },
    ],
  },
];
