/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-10-28 17:42:49
 * @Last Modified by: chenhq
 * @Last Modified time: 2022-02-14 11:28:26
 * @Description: 项目管理
 * @Route: Route
 */

// const DefaultLayout = () =>
// import(/* webpackChunkName: "default" */ '@/components/layout/default');

const index = () => import(/* webpackChunkName: "member-detail" */ '@/pages/member-list/index');


export default [

    // {
    //     path: 'member-list',
    //     redirect: 'member-list/index',
    //     meta: {
    //         title: '会员列表',
    //         code: 'PZS_HYLB',
    //         //  display: 'none',
    //     },
    // },
    {
        path: 'member-list/index',
        // component: () => import(/* webpackChunkName:
        // "member-detail" */ '@/pages/member-list/index'),
        component: index,
        meta: {
            title: '会员列表',
            // code: 'PZS_HYLB',
            code: 'ALL',
            menuIconName: 'member-manerge@3x.png',
        },
    },
    {
        path: 'member-list/member-detail',
        component: () => import(/* webpackChunkName: "member-detail" */ '@/pages/member-list/member-detail'),
        meta: {
            title: '会员详情',
            code: 'PZS_HYLB_CKXQ',
            display: 'none',
        },
    },
    {
        path: 'member-list/input-message',
        component: () => import(/* webpackChunkName: "input-message" */ '@/pages/member-list/input-message'),
        meta: {
            title: '录入信息',
            code: 'PZS_FWLB_EDIT',
            display: 'none',
        },
    },
    {
        path: 'member-list/set-record',
        component: () => import(/* webpackChunkName: "set-record" */ '@/pages/member-list/set-record'),
        meta: {
            title: '录入信息',
            code: 'PZS_FWLB_EDIT',
            display: 'none',
        },
    },
    {
        path: 'member-list/evaluation-result',
        component: () => import(/* webpackChunkName: "evaluation-result" */ '@/pages/member-list/evaluation-result'),
        meta: {
            title: '测评结果',
            code: 'PZS_FWLB_EDIT',
            display: 'none',
        },
    },
    {
        path: 'member-list/health-assessment',
        component: () => import(/* webpackChunkName: "member-list" */ '@/pages/member-list/health-assessment/index'),
        meta: {
            title: '健康评估',
            code: 'PZS_HYLB',
            display: 'none',
        },
    },
    {
        path: 'member-list/physical-examination-report',
        component: () => import(/* webpackChunkName: "member-list" */ '@/pages/member-list/physical-examination-report/index'),
        meta: {
            title: '体检报告解读',
            code: 'PZS_HYLB',
            display: 'none',
        },
    },

];
