export default [
  {
    path: 'adverseEvent',
    component: () => import('@/pages/AdverseEvent/index'),
    meta: {
      title: '不良事件',
      code: 'PZS_BLSJ',
      menuIconName: 'adverse-event.png',
    },
  },
  {
    path: 'adverseEvent/add',
    component: () => import('@/pages/AdverseEvent/details'),
    meta: {
      title: '新增不良事件',
      code: 'PZS_BLSJ',
      display: 'none',
    },
  },
  {
    path: 'adverseEvent/details',
    component: () => import('@/pages/AdverseEvent/details'),
    meta: {
      title: '不良事件详情',
      code: 'PZS_BLSJ',
      display: 'none',
    },
  },
];
