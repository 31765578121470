/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2020-03-27 13:56:13
 * @Last Modified by: huihua.he
 * @Last Modified time: 2021-07-14 16:53:29
 * @Description: 常量配置项
 * @Route: Route
 */
export default {
    TOKEN_KEY: 'authentication',
    bizCode: 'QB',
    responseProps: {
        code: 'code', // 响应状态码
        data: 'data', // 响应数据
        message: 'message', // 响应文案信息
    },
    responseListProps: {
        results: 'list', // 响应数组数据
        pageSize: 'pageSize', // 页长
        pageNumber: 'pageNumber', // 页码
        totalCount: 'totalRecord', // 页面数据总量
        cursorCode: 'cursorCode', // 游标标记字段
    },
    // 文件响应数据键值
    responseFileProps: {
        fileId: 'id', // 文件标识字段
        fileUrl: 'fileUrl', // 文件地址
        fileName: 'fileName', // 文件名
        fileSuffix: 'suffix', // 文件后缀
    },
    PROD: 'swrecruit.medbanks.cn',
    TEST: 'recruit.medbanks-test.com',
    LOGINURL: '//center.medbanks.cn/loginIndex',
    projectCover: 'https://middlend-public-1300562124.cos.ap-beijing.myqcloud.com/3t9pxxppq48jpcqhyod9.jpg',
};
